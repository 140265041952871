import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 95",
  week: "Semana 14",
  day: "16",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-16",
  path: "/cronologia/semana-14#dia-16-jun/",
};

const Day95 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.665 casos mediante pruebas PCR. En ese mismo
          periodo, 86 personas han requerido hospitalización (5,2% de los
          diagnósticos), de los que seis han sido ingresos en la UCI, y se han
          producido 25 fallecimientos.
        </ModText>
        <ModText>
          El Gobierno ha aprobado el <strong>Fondo COVID-19</strong> para
          comunidades y ciudades autónomas, dotado con 16.000 millones de euros.
          Es un fondo no reembolsable, por lo que no tendrán que devolver los
          recursos, no pagarán intereses y no les supondrá mayor deuda.
        </ModText>
        <ModImage
          src="/images/svg/fondo-inversion-recursos.svg"
          alt="fondos para la recuperacion covid-19"
        />

        <ModText>El objetivo del Fondo COVID-19 es:</ModText>
        <ModDotList>
          Garantizar recursos a las CC.AA. para que puedan prestar servicios
          públicos de calidad.
        </ModDotList>
        <ModDotList>
          Financiar gastos derivados de la pandemia (la mayor parte se destina a
          gasto sanitario)
        </ModDotList>
        <ModDotList>Compensar la caída de los ingresos autonómicos.</ModDotList>
        <ModText>
          La Comision Europea ha habilitado una{" "}
          <InlineLink link="http://reopen.europa.eu">
            herramienta interactiva
          </InlineLink>{" "}
          para reanudar de manera segura la{" "}
          <strong>libre circulación y el turismo</strong> en la UE. Ofrece
          información en tiempo real sobre fronteras, medios de transporte
          disponibles, restricciones de viaje o medidas de salud pública, y está
          disponible en 24 lenguas.
        </ModText>
        <ModAnimation svg="/images/anim/playa.svg" />

        <ModText>
          El Ministerio de Transportes, Movilidad y Agenda Urbana ha hecho una
          recopilación de todas las{" "}
          <InlineLink link="https://www.mitma.gob.es/ministerio/covid-19/covid-19-trabajamos-en-que-te-muevas-con-seguridad/">
            medidas de protección y seguridad
          </InlineLink>{" "}
          que se están aplicando en los espacios y medios de transporte público.
        </ModText>
        <ModText>
          La <strong>Unidad de Mujeres y Ciencia</strong> (UMyC) del Ministerio
          de Ciencia e Innovación ha analizado cómo ha afectado el contexto del
          COVID-19 al sistema de ciencia e innovación en términos de igualdad de
          género, en un documento en el que incorpora un{" "}
          <strong>conjunto de recomendaciones</strong> a tener en cuenta para la
          toma de futuras decisiones.
        </ModText>
        <ModText>
          Las Conferencias Sectoriales de Agricultura y de Pesca han acordado la
          distribución de 36,6 millones de euros para apoyar a las explotaciones
          de <strong>ovino y caprino, y al sector pesquero</strong>, afectadas
          por estado de alarma.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.mitma.gob.es/ministerio/covid-19/covid-19-trabajamos-en-que-te-muevas-con-seguridad/"
            name="Guia: Trabajamos en que te muevas con seguridad"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/ciencia-e-innovacion/Documents/2020/16062020_GeneroyCiencia.PDF"
            name="Género y ciencia frente al coronavirus"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day95;
